"use client";
import Image from "next/image";
import { useEffect, useState } from "react";
import { classNames } from "../utils/formatters";
import Link from "next/link";
import useWindowDimensions from "../hooks/useViewportWidth";
import { bannersData as bannerData } from "../utils/constants";
import { event } from "../lib/gtag";

const sorted = [...bannerData].sort(() => Math.random() - 0.5);
export default function GridBanner({
  singleBanner,
}: {
  singleBanner?: boolean;
}) {
  const [bannerIdx, setBannerIdx] = useState(0);

  const { width } = useWindowDimensions();
  const [data, setData] = useState(sorted);

  useEffect(() => {
    //if sm, lets get rid of the last 3 elements from banners data, matching our 2 columns
    if (width >= 640 && width < 768) {
      return setData(sorted.slice(0, -3));
    }
    if (width >= 768 && width < 1024) {
      return setData(sorted.slice(0, -2));
    }
    return setData(sorted);
  }, [width]);

  useEffect(() => {
    const interval = setInterval(() => {
      setBannerIdx(bannerIdx === sorted.length - 1 ? 0 : bannerIdx + 1);
    }, 6000);
    return () => clearInterval(interval);
  }, [bannerIdx]);

  function getBannerClass(bannerIdx, idx) {
    let bannerClass = "flex flex-col";

    if (bannerIdx !== idx && !singleBanner) {
      bannerClass = "hidden sm:flex flex-col";
    } else if (bannerIdx !== idx && singleBanner) {
      bannerClass = "hidden";
    }

    return bannerClass;
  }

  function trackBannerClick(trackId: string) {
    event({
      action: "click_banner",
      category: "Ads",
      label: trackId,
    });
  }

  return null;
  // return (
  //   <div className={"mx-auto  w-full text-center "}>
  //     <div className=" text-left">
  //       <div
  //         className={classNames(
  //           "grid gap-y-4 gap-x-2",
  //           singleBanner
  //             ? "grid-cols-1"
  //             : `grid-cols-1 sm:grid-cols-${
  //                 bannerData.length === 3 ? "3" : "2"
  //               } md:grid-cols-3 lg:grid-cols-5`
  //         )}
  //       >
  //         {data.map((banner, idx) => {
  //           const { id, image, link, linkLabel, trackId } = banner;
  //           return (
  //             <div
  //               key={id}
  //               className={classNames(
  //                 `group
  //                  relative cursor-pointer pb-2   sm:rounded-md shadow-md bg-gray-100 `,
  //                 getBannerClass(bannerIdx, idx)
  //               )}
  //             >
  //               <div className="relative w-full min-h-60   bg-gray-200 aspect-w-4 aspect-h-2  overflow-hidden group-hover:opacity-75 ">
  //                 <div className="h-full">
  //                   <Link
  //                     href={link}
  //                     target="_blank"
  //                     onClick={() => trackBannerClick(trackId)}
  //                   >
  //                     <div className="absolute h-full w-full">
  //                       <Image
  //                         fill
  //                         style={{
  //                           objectFit: "cover",
  //                         }}
  //                         sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
  //                         src={image}
  //                         // alt={item.imageAlt}
  //                         alt={`banner`}
  //                         className={classNames(
  //                           "w-full h-full object-center object-cover sm:rounded-t-md"
  //                         )}
  //                       />
  //                     </div>
  //                   </Link>
  //                 </div>
  //               </div>
  //               <div className="flex items-center justify-end gap-x-1.5 mr-1 pt-2 px-2">
  //                 <Link
  //                   href={link}
  //                   target="_blank"
  //                   onClick={() => trackBannerClick(trackId)}
  //                 >
  //                   <span className="text-sm font-semibold leading-6 text-orange-500 hover:text-orange-400">
  //                     {linkLabel || "Quiero saber más"}{" "}
  //                     <span aria-hidden="true">→</span>
  //                   </span>
  //                 </Link>
  //               </div>
  //             </div>
  //           );
  //         })}
  //       </div>
  //     </div>
  //   </div>
  // );
}
